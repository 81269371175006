class ClientError extends Error {}

export default class ClientLogin {
    constructor() {
    }

    static init() {
        const resaltInput = document.querySelector('#client_field_13898166');
        const wrapResaltInput = resaltInput?.parentNode as HTMLElement;

        if (wrapResaltInput) {
            wrapResaltInput.style.display = 'none';

            ClientLogin.setCheckedSubscribeMethod();
            ClientLogin.toggleViewSubsribeMethod();
            ClientLogin.setValueCheckboxInForm();
        }

        ClientLogin.setPlaceholderForInput();
    }

    static setPlaceholderForInput() {
        const inputName = document.querySelector('input#client_name');
        const inputPhone = document.querySelector('input#client_phone');
        const inputEmail = document.querySelector('input#client_email');
        const email = document.querySelector('#email');
        const inputPass = document.querySelector('input#client_password');
        const inputPassDubl = document.querySelector('input#client_password_confirmation');
        const shippingAddress = document.querySelector('#shipping_address_full_locality_name');
        const shippingAddressAddress = document.querySelector('#shipping_address_address');
        const orderComment = document.querySelector('#order_comment');

        const clientOldPassword = document.querySelector('#client_old_password');
        const clientPassword = document.querySelector('#client_password');
        const password = document.querySelector('#password');
        const clientPasswordConfirmation = document.querySelector('#client_password_confirmation');

        inputName?.setAttribute('placeholder', 'Ваше имя');
        inputPhone?.setAttribute('placeholder', 'Ваш телефон');
        inputEmail?.setAttribute('placeholder', 'Ваш email');
        email?.setAttribute('placeholder', 'Ваш email');
        inputPass?.setAttribute('placeholder', 'Пароль');
        inputPassDubl?.setAttribute('placeholder', 'Повторите пароль');
        shippingAddress?.setAttribute('placeholder', 'Город');
        shippingAddressAddress?.setAttribute('placeholder', 'Адрес');
        orderComment?.setAttribute('placeholder', 'Комментарий');

        clientOldPassword?.setAttribute('placeholder', 'Старый пароль');
        clientPassword?.setAttribute('placeholder', 'Пароль');
        password?.setAttribute('placeholder', 'Пароль');
        clientPasswordConfirmation?.setAttribute('placeholder', 'Повторите пароль');
    }

    static setCheckedSubscribeMethod() {
        const subscribeWrap = document.querySelector('.co-input--subscribe');

        const checkboxToggleWrap = document.createElement('div');
        const checkboxToggleContent = `
            <label>
                <input type="checkbox" name="method_subscription2" value="e-mail">
                <span class="lable-input">Электронная почта</span>
            </label>
            <label>
                <input type="checkbox" value="sms" name="method_subscription">
                <span class="lable-input">SMS</span>
            </label>
        `;

        checkboxToggleWrap.innerHTML = checkboxToggleContent;
        checkboxToggleWrap.classList.add('subscription-method');

        subscribeWrap?.append(checkboxToggleWrap);
    }

    // Вспомогательный метод для toggleViewSubsribeMethod
    static openAndCloseSubsctimeMethod(mainCheckbox: HTMLInputElement, subscribeMethod: HTMLElement) {
        if (mainCheckbox?.checked) {
            subscribeMethod.classList.add('subscription-method__is-active');
        } else {
            subscribeMethod.classList.remove('subscription-method__is-active');
        }
    }

    static toggleViewSubsribeMethod() {
        const mainCheckbox = document.querySelector<HTMLInputElement>('#client_subscribe');
        const subscribeMethod = document.querySelector<HTMLElement>('.subscription-method');

        if (subscribeMethod === null) {
            throw new ClientError('Методы подписки не обнаружены на странице %{subscription-method}');
        }
        if (mainCheckbox === null) {
            throw new ClientError('Не определет input для упрапвления списобами подписки');
        }

        mainCheckbox.removeAttribute('checked');
        ClientLogin.openAndCloseSubsctimeMethod(mainCheckbox, subscribeMethod);

        mainCheckbox.addEventListener('input', () => {
            ClientLogin.openAndCloseSubsctimeMethod(mainCheckbox, subscribeMethod);
        });
    }

    static setValueCheckboxInForm() {
        const subscribeMethod = document.querySelector<HTMLElement>('.subscription-method');

        if (! subscribeMethod) {
            return new ClientError('subscribeMethod не определен');
        }

        const fieldEmail = subscribeMethod?.querySelector<HTMLInputElement>('input[value="e-mail"]') as HTMLInputElement;
        const fieldSms = subscribeMethod?.querySelector<HTMLInputElement>('input[value="sms"]') as HTMLInputElement;

        const resultFieldEmail = document.querySelector<HTMLInputElement>('#client_field_21185233');
        const resultFieldSms = document.querySelector<HTMLInputElement>('#client_field_21185234');

        if (! resultFieldEmail || ! resultFieldSms) {
            return new ClientError('Не определены системные поля для записи значений. Поля выводятся из бек-офиса');
        }

        if (! fieldEmail && ! fieldSms) {
            return;
        }

        fieldEmail.addEventListener('change', () => {
            if (fieldEmail.checked) {
                resultFieldEmail.checked = true;
            } else {
                resultFieldEmail.checked = false;
            }
        });

        fieldSms.addEventListener('change', () => {
            if (fieldSms.checked) {
                resultFieldSms.checked = true;
            } else {
                resultFieldSms.checked = false;
            }
        });
    }

    static pageTitleFromUrl() {
        function openTitle(page: string) {
            const titles = document.querySelectorAll('.client-login__title-item');

            titles?.forEach(title => {
                title.classList.remove('client-login__title-active');

                if (title.classList.contains(page)) {
                    title.classList.add('client-login__title-active');
                }
            });
        }

        switch (window.location.pathname) {
            case '/client_account/password/change':
                openTitle('password-change');
                break;
            case '/client_account/contacts/new':
                openTitle('registration');
                break;
            case '/client_account/session/new':
                openTitle('login');
                break;
            case '/client_account/login':
                openTitle('login');
                break;
            default:
                break;
        }
    }
}
