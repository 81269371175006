const ROOT_СLASS = 'select';
const ROOT_SELECTOR = `.${ROOT_СLASS}`;

export class SelectItem {
    private node: HTMLElement;
    private button: HTMLButtonElement | null;
    private buttonTextNode: HTMLSpanElement | null;
    private list: HTMLDivElement | null;
    private items: NodeListOf<HTMLElement> | null;
    private select: HTMLSelectElement | null;

    constructor(node: HTMLElement) {
        this.node = node;
        this.button = node.querySelector<HTMLButtonElement>(`${ROOT_SELECTOR}__btn`);
        this.buttonTextNode = this.button?.querySelector('span') ?? null;
        this.list = node.querySelector<HTMLDivElement>(`${ROOT_SELECTOR}__list`);
        this.select = node.querySelector<HTMLSelectElement>(`${ROOT_SELECTOR} select`);

        this.connectionCallback();
    }

    private connectionCallback() {
        if (this.buttonTextNode && this.select) {
            this.buttonTextNode.textContent = this.select.options[this.select.selectedIndex].text;
        }

        this.renderList();

        this.items = this.node.querySelectorAll<HTMLElement>(`${ROOT_SELECTOR}__item`);
        this.setActiveItem(this.items);
        this.items.forEach(item => {
            item.addEventListener('click', () => {
                this.hide();

                if (this.buttonTextNode) this.buttonTextNode.textContent = item.textContent;

                if (this.select) {
                    const value = item.getAttribute('data-option') ?? '';

                    this.select.value = value;
                    this.select.dispatchEvent(new Event('change'));
                    this.setActiveItem(this.items!);
                }
            });
        });

        this.button?.addEventListener('click', () => this.toggle());
    }

    private setActiveItem(items: NodeListOf<HTMLElement>) {
        let result = '';

        items.forEach(item => {
            const value = item.getAttribute('data-option') ?? '';

            if (this.select?.value === value) {
                result = value;
                item.classList.add(`${ROOT_СLASS}__item_active`);
            } else {
                item.classList.remove(`${ROOT_СLASS}__item_active`);
            }
        });

        return result;
    }

    private renderList() {
        if (! this.select) return '';

        const options = this.select.options;
        const itemsArr: string[] = [];

        for (let i = 0; i < options.length; i++) {
            itemsArr.push(`<span class="select__item" data-option="${options[i].value}">${options[i].text}</span>`);
        }

        if (this.list) this.list.innerHTML = itemsArr.join('');
    }

    show() {
        this.list?.classList.add(`${ROOT_СLASS}__list_active`);
    }

    hide() {
        this.list?.classList.remove(`${ROOT_СLASS}__list_active`);
    }

    toggle() {
        this.list?.classList.toggle(`${ROOT_СLASS}__list_active`);
    }
}

export class Select {
    private list: Map<HTMLElement, SelectItem> = new Map();

    constructor() {
        this.connectionCallback();
    }

    private connectionCallback() {
        const selectNodes = document.querySelectorAll<HTMLElement>('.select');

        selectNodes.forEach(select => {
            if (! this.list.has(select)) {
                this.list.set(select, new SelectItem(select));
            }
        });

        document.addEventListener('click', evt => {
            const node = evt.target as HTMLElement | null;

            if (! node?.closest(`${ROOT_SELECTOR}`)) {
                this.closeAll();
            }
        });
    }

    closeAll() {
        this.list.forEach(item => item.hide());
    }
}
