import $ from 'jquery';
import { faro } from '@gate31/faro/src';
import { ReviewsApi } from '@gate31/core/src/api/MneniyaApi';
import { ReviewTypes, Review, Stats } from '@gate31/types/ReviewsTypes';
import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import { DEFAULT_TEXT, Reviews } from './reviews';

class ReviewError extends Error {
    name = 'ReviewError'
}

interface ReviewsShopListOpts {
    api: ReviewsApi;
    selectors: {
        reviewsListWrapper: string;
    };
}
interface ReviewsShopFormOpts {
    api: ReviewsApi;
    selectors: {
        form: string;
    };
}

export class ReviewsShop {
    api: ReviewsApi;
    reviewsShopList: ReviewsShopList;
    reviewsShopForm: ReviewsShopForm;

    reviewOpenBtn: HTMLElement | null;

    constructor() {
        this.api = new ReviewsApi();

        this.reviewsShopList = new ReviewsShopList({
            api: this.api,
            selectors: {
                reviewsListWrapper: '[data-reviews-list]'
            }
        });

        this.reviewsShopList.init();

        this.reviewsShopForm = new ReviewsShopForm({
            api: this.api,
            selectors: {
                form: '[data-review-form]'
            }
        });

        this.reviewOpenBtn = document.querySelector<HTMLElement>('[review-open-btn]') as HTMLElement;
        this.reviewOpenBtn?.addEventListener('click', () => {
            this.openReviewForm();
        });
    }

    openReviewForm() {
        if (! this.reviewOpenBtn) {
            return;
        }

        const reviewFormWrap = document.querySelector('[data-review-form-wrap]');

        this.reviewOpenBtn.style.display = 'none';
        reviewFormWrap?.setAttribute('data-review-form-wrap', 'true');
    }
}
export class ReviewsShopList {
    api: ReviewsApi;
    reviewsList: Array<Review> | [];
    reviewsStats: Stats | undefined;
    reviewsListWrapper: HTMLDivElement | null;

    constructor(opts: ReviewsShopListOpts) {
        this.api = opts.api;

        this.reviewsList = [];
        this.reviewsStats;
        this.reviewsListWrapper = document.querySelector<HTMLDivElement>(opts.selectors.reviewsListWrapper);

        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.getAttribute('data-reviews-next-button')) {
                const offset = Number(target.getAttribute('data-reviews-next-button'));

                if (! offset) {
                    return;
                }
                this.loadMoreProcess(offset);
            }
        });
    }

    async loadMoreProcess(offset: number): Promise<void> {
        await this.getReviewsList(offset);
        this.renderReviewsList();
        this.reRenderButton(offset);
    }

    async getReviewsStats(): Promise<Stats | undefined> {
        const reviewsData = await this.getReviewsData(0);

        if (! reviewsData) {
            return;
        }

        this.reviewsStats = reviewsData.Stats;
        return reviewsData.Stats;
    }

    async getReviewsList(offset: number = 0): Promise<Review[] | undefined> {
        const reviewsData = await this.getReviewsData(offset);

        if (! reviewsData) {
            return;
        }

        this.reviewsList = reviewsData.Reviews;
        return reviewsData.Reviews;
    }

    getReviewsData(offset: number): Promise<ReviewTypes | void> {
        return this.api.getReviewsAll(offset)
            .then(res => res)
            .catch(error => {
                const reviewError = new ReviewError(error);

                faro.api.pushError(reviewError, {
                    context: {
                        section: 'review-list'
                    }
                });

                return;
            });
    }

    reRenderButton(offset: number = 0): void {
        if (! this.reviewsListWrapper || ! this.reviewsStats) {
            return;
        }

        const oldButton = document.querySelector(`[data-reviews-next-button="${offset}"]`);

        if (oldButton) {
            oldButton.remove();
        }

        if (Number(offset) < this.reviewsStats.ReviewsTotalCount) {
            this.reviewsListWrapper.innerHTML += LiquidRender.render('reviews-next-button-template', { data_offset: offset + 10 });
        }
    }

    renderStatReviews() {
        // todo Переписать

        if (! this.reviewsStats) {
            return;
        }

        const ReviewsTotalCount = this.reviewsStats.ReviewsTotalCount;
        const ratingTotal = this.reviewsStats.RatesTotalSum / ReviewsTotalCount;

        const counterWrapHtml = document.querySelector('[data-review-counter]');
        const ratingWrapHtml = document.querySelector('[data-review-rating]');

        if (counterWrapHtml && ratingWrapHtml) {
            counterWrapHtml.innerHTML = String(ReviewsTotalCount);
            ratingWrapHtml.innerHTML = ratingTotal.toFixed(2);
        }
    }

    renderReviewsList() {
        if (! this.reviewsListWrapper) {
            return;
        }

        if (! this.reviewsList && ! (this.reviewsList as Array<Review>).length) {
            return;
        }

        const listReviewsTemplate = this.reviewsList.map(item => {
            item.Date = (item.Date as string).split('T')[0].split('-').reverse().join('.');

            return LiquidRender.render('reviews-item-template', { item });
        }).join('');

        this.reviewsListWrapper.innerHTML += listReviewsTemplate;
    }

    async init() {
        await this.getReviewsStats();
        this.renderStatReviews();
        await this.getReviewsList();
        this.renderReviewsList();
        this.reRenderButton();
    }
}
export class ReviewsShopForm {
    api: ReviewsApi;
    form: HTMLFormElement | null;
    reviews: Reviews;

    reviewsResultWrap: HTMLDivElement | null;
    reviewsFormWrap: HTMLDivElement | null;

    constructor(opts: ReviewsShopFormOpts) {
        this.api = opts.api;
        this.form = document.querySelector<HTMLFormElement>(opts.selectors.form);

        this.reviewsResultWrap = document.querySelector<HTMLDivElement>('[data-review-result]');
        this.reviewsFormWrap = document.querySelector<HTMLDivElement>('[data-content-reviews]');

        this.reviews = new Reviews({
            reviewsResultWrap: this.reviewsResultWrap,
            reviewsFormWrap: this.reviewsFormWrap
        });

        if (! this.form) {
            return;
        }

        this.form.addEventListener('submit', async e => {
            e.preventDefault();

            const formData = new FormData((this.form as HTMLFormElement));
            const formIsValid = this.isNotEligableForDisability(formData);

            if (! formIsValid) {
                this.renderErrorField(formData);
                return;
            }

            // Отправить форму
            await this.sendForm(formData)
                .then(data => {
                    if (data.Message !== 'Review is added to database and awaiting moderation' || ! data.ReviewID) {
                        this.reviews.renderResult({
                            title: DEFAULT_TEXT.ERROR_TITLE,
                            message: DEFAULT_TEXT.ERROR_MESSAGE,
                            isError: true
                        }, 3000);

                        return;
                    }

                    this.reviews.renderResult({
                        title: DEFAULT_TEXT.SUCCESS_TITLE,
                        message: DEFAULT_TEXT.SUCCESS_MESSAGE,
                        isError: false
                    }, 0);
                })
                .catch(() => {
                    this.reviews.renderResult({
                        title: DEFAULT_TEXT.ERROR_TITLE,
                        message: DEFAULT_TEXT.ERROR_MESSAGE,
                        isError: true
                    }, 3000);
                })
                .finally(() => {});
        });
    }

    /**
    * Проверка полей на наличие содержимого
    * @param formData
    */
    isNotEligableForDisability(formData: FormData) {
        return ((formData.get('first-name') !== '') && (formData.get('email') !== '') && (formData.get('comment') !== '') && (Number(formData.get('review[rating]')) > 0));
    }

    sendForm(formData: FormData) {
        if (! this.form) {
            return Promise.resolve();
        }

        const body = {
            token: 'n{MP&v(IFaOVk;/N',
            AuthorName: formData.get('first-name'),
            AuthorEmail: formData.get('email'),
            ReviewText: formData.get('comment'),
            Rate: formData.get('review[rating]'),
            Date: String(new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]),
            Recommends: 'true'
        };

        return this.api.createReview(body);
    }

    renderErrorField(formData: FormData) {
        filedErorr('first-name');
        filedErorr('email');
        filedErorr('comment');

        function filedErorr(str: string) {
            if (formData.get(str) === '') {
                const filed = document.querySelector(`[name="${str}"]`);

                filed?.classList.add('is-error');
                filed?.addEventListener('input', () => {
                    filed?.classList.remove('is-error');
                });
            }
        }

        if ($('[name="review[rating]"]:checked').length === 0) {
            $('.reviews__shop-form-need_rating').remove();

            $('.reviews__shop-form-rating-rating').append('<p class="reviews__shop-form-need_rating">Необходимо установить рейтинг</p>');

            $('[name="review[rating]"]').on('input', () => {
                $('.reviews__shop-form-need_rating').remove();
            });
        }
    }
}
