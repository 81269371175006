import { validateEmail } from '@gate31/core/src/libs/utils';
import { Gate31ApiV2 } from '@gate31/core/src/api/gate31-api';
import { faro } from '@gate31/faro/src';
import Inputmask from 'inputmask';
import Recaptcha from '@gate31/feature/recaptcha/recaptcha';
import { ConfirmPhone } from '../confirm-phone/confirm-phone';

interface RequestError {
    code: string;
    message: string;
}

export default class CreateAccount {
    form: HTMLFormElement;
    loader: HTMLDivElement;
    subscriptionMethod: {
        button: HTMLInputElement | null;
        content: HTMLElement | null;
    };
    im: Inputmask.Instance;
    recaptchId: number;
    recaptchaInstanse: Recaptcha;
    configPhone: ConfirmPhone;
    cb?: () => void

    constructor(cb?: () => void) {
        this.cb = cb;
        this.im = new Inputmask('99.99.9999');
        this.im.mask('input[type="text"].type-date');

        this.form = document.querySelector('[data-from-create-account]') as HTMLFormElement;
        this.loader = document.querySelector('.create-account__loader') as HTMLDivElement;

        if (! this.form) {
            return;
        }

        this.listenerField();
        this.handleForm();

        this.configPhone = new ConfirmPhone({
            id: 'create-account-page'
        });
    }

    redirectToAccount() {
        window.location.href = '/client_account/login';
    }

    showLoader() {
        this.loader.classList.add('show');
    }

    hideLoader() {
        this.loader.classList.remove('show');
    }

    handleForm() {
        this.form.addEventListener('submit', e => {
            e.preventDefault();

            this.formatFormFieldsToCorrectValue();
            const formData = new FormData(this.form);
            const isValidForm = this.fieldValidation(formData);

            if (! isValidForm.valid) {
                isValidForm.invalidFieldName.forEach(fieldName => {
                    if (fieldName === 'phone') {
                        // За вывод ошибок для телефона отвечает класс confirmPhone
                        this.configPhone.renderNotConfirmError();
                    } else {
                        this.openFieldError(fieldName);
                    }
                });

                return;
            }

            this.showLoader();

            const formBody = this.prepareFormData(formData);

            Gate31ApiV2.createAccount(formBody)
                .then(response => {
                    this.hideLoader();

                    if (response?.status === 'Success') {
                        this.openResultMessage();

                        setTimeout(() => {
                            this.redirectToAccount();
                        }, 7000);

                        if (this.cb) {
                            this.cb();
                        }
                    }

                    if (response.error) {
                        const errorCode = response?.error?.cause?.code?.code;
                        const cause = response?.error?.cause;
                        const code = cause ? cause?.code?.code : 0;
                        const message = cause ? cause?.phone?.[0] || cause?.email?.[0] : '';

                        if (message) {
                            this.openRequestError(message);
                            return;
                        }

                        if (code) {
                            this.handleRequestError(errorCode);
                            return;
                        }
                    }

                    throw new Error();
                })
                .catch(error => {
                    try {
                        faro.api.pushError(error, {
                            context: {
                                component: 'CreateAccount',
                                jsonError: JSON.stringify(error)
                            }
                        });
                    } catch (sendError: unknown) {
                        faro.api.pushError(sendError as Error, {
                            context: {
                                component: 'CreateAccount'
                            }
                        });
                    }

                    this.hideLoader();

                    this.openRequestError('Что-то пошло не так. Пожалуйста повторите попытку...');
                });
        });
    }

    handleRequestError(error: RequestError) {
        switch (error.code) {
            case 'INVALID_PHONE':
                this.openRequestError('Укажите корректный номер телефона');
                break;
            case 'INVALID_DATE':
                this.openRequestFieldError('Некорректная дата', 'dateOfBirth');
                break;
            case 'INVALID_EMAIL':
                this.openRequestError('Укажите корректный email');
                break;
            case 'INVALID_USER_NAME':
                this.openRequestError('Необходимо указать имя');
                break;
            case 'INVALID_PASSWORD':
                this.openRequestError('Длина пароля не должна быть меньше 6 символов');
                break;
            case 'ERROR_GRECAPTCHA':
                this.openRequestError('Подтвердите что вы не робот');
                break;
            default:
                this.openRequestError('Что-то пошло не так. Пожалуйста повторите попытку...');
                break;
        }
    }

    openRequestError(message: string) {
        const errorNode = document.querySelector('.create-account__result-error') as HTMLUListElement;

        errorNode.innerHTML = message;

        errorNode?.classList.add('create-account__result-error_active');

        setInterval(() => {
            errorNode?.classList.remove('create-account__result-error_active');
        }, 5000);
    }

    openResultMessage() {
        const $formBody = document.querySelector<HTMLElement>('.create-account__form-body');
        const $formResult = document.querySelector<HTMLElement>('.create-account__result-message');
        const $formTitle = document.querySelector<HTMLDivElement>('.create-account__title');

        if (! $formResult || ! $formBody) {
            throw new Error('Невозможно показать учпешный результат регистрации');
        }

        if ($formTitle) {
            $formTitle.innerHTML = 'Добро пожаловать в GATE31!';
        }

        $formBody.classList.add('hidden');
        $formResult.classList.remove('hidden');
    }

    listenerField() {
        this.form.addEventListener('change', e => {
            const fieldName = (e.target as HTMLInputElement).getAttribute('name') as string;

            if (fieldName) {
                this.hiddenFieldError(fieldName);
            }
        });
    }

    formatFormFieldsToCorrectValue(): void {
        // Iterate over all fields when trimming is needed
        for (const fieldName of [ 'firstName', 'lastName', 'email' ]) {
            const fieldWhenNeedTrimming = this.form.querySelector<HTMLInputElement>(`input[name="${fieldName}"]`);

            if (fieldWhenNeedTrimming) {
                fieldWhenNeedTrimming.value = fieldWhenNeedTrimming.value.trim();
            }
        }
    }

    fieldValidation(formData: FormData): {
        valid: false;
        invalidFieldName: Array<string>;
    } | { valid: true } {
        let isValid = true;
        const errorFieldList: Array<string> = [];

        if (! /[0-9][0-9]\.[0-9][0-9]\.[0-9][0-9][0-9][0-9]/.test((formData.get('dateOfBirth') as string)) && (formData.get('dateOfBirth') as string) !== '') {
            isValid = false;
            errorFieldList.push('dateOfBirth');
        }
        if (! formData.get('firstName')) {
            isValid = false;
            errorFieldList.push('firstName');
        }
        if (! formData.get('email') || ! validateEmail(formData.get('email'))) {
            isValid = false;
            errorFieldList.push('email');
        }
        if (formData.get('password') !== formData.get('password-2')) {
            isValid = false;
            errorFieldList.push('password-2');
        }
        if (formData.get('is-confirm') !== '1') {
            isValid = false;
            errorFieldList.push('is-confirm');
        }

        if (! this.configPhone.confirmed) {
            isValid = false;
            errorFieldList.push('phone');
        }

        if (isValid === false) {
            return {
                valid: false,
                invalidFieldName: errorFieldList
            };
        }

        return {
            valid: true
        };
    }

    openFieldError(fieldName: string) {
        const errorNode = document.querySelector(`[data-input-wrap="${fieldName}"] .create-account__field-error`) as HTMLElement;

        errorNode.classList.add('create-account__field-error_active');

        return `invalid field: ${fieldName}`;
    }

    hiddenFieldError(fieldName: string) {
        const errorNode = document.querySelector(`[data-input-wrap="${fieldName}"] .create-account__field-error`) as HTMLElement;

        if (! errorNode) {
            return;
        }

        errorNode.classList.remove('create-account__field-error_active');
    }

    transformDateOfBirth(date: string): string {
        return date ? date.split('.').reverse().join('-') || '' : '';
    }

    openRequestFieldError(message: string, field: string) {
        const node = document.querySelector(`[data-input-wrap="${field}"] .create-account__field-error`) as HTMLElement;

        if (node) {
            node.innerHTML = message;
            node.classList.add('create-account__field-error_active');
        }
    }

    prepareFormData(formData: FormData) {
        const paramsFromUrl = new URLSearchParams(window.location.search);
        const shopValue = paramsFromUrl.get('shop') ? decodeURI((paramsFromUrl.get('shop') as string)) : '';

        const opts: {
            grecaptcha?: string;
            isUseCaptcha: boolean;
        } = {
            isUseCaptcha: false
        };

        const phoneField = this.form.querySelector<HTMLInputElement>('[name="phone"]');
        const phone = phoneField ? phoneField.value : null;

        const body = {
            ...opts,
            email: formData.get('email') as string,
            phone: phone as string,
            password: formData.get('password') ? formData.get('password') as string : '',
            dateOfBirth: this.transformDateOfBirth((formData.get('dateOfBirth') as string)),
            name: {
                firstName: formData.get('firstName') as string,
                lastName: formData.get('lastName') ? formData.get('lastName') as string : ''
            },
            endpoint: {
                shop: shopValue
            },
            subscriptions: {
                email: true,
                phone: true
            }
        };

        return body;
    }
}
