import Subscription from '@gate31/uikit/common/components/mindbox/mindboxSubscription';
import { cookieModal } from '@gate31/uikit/common/components/cookie-modal/cookie-modal';

export const footer = () => {
    Subscription.init('.footer__subscribe form');

    if (document.querySelector('[data-cookie-close]')) {
        cookieModal();
    }
};
