import '@gate31/faro/src';
import EventBus from 'EventBus';
import $ from 'jquery';
import Favorite from '@gate31/uikit/common/components/favorite/favorite';
import { formDescr } from '@gate31/uikit/common/components/forms/forms';
import Client from '@gate31/uikit/common/components/client/client';
import Accordion from '@gate31/uikit/common/components/accordion/accordion';
import ClientLogin from '@gate31/uikit/common/components/client-login/client-logian';
import { SlimBannerTouch } from '@gate31/touch/components/SlimBannerTouch/SlimBannerTouch';
import { Select } from '@gate31/uikit/common/components/select/select';
import { MindboxApi, serCartBody } from '@gate31/core/src/api/mindboxApi';
import Subscription from '@gate31/uikit/common/components/mindbox/mindboxSubscription';
import { ReviewsShop } from '@gate31/feature/reviews/reviews-shop';
import { header } from '../components/header/header';
import CreateAccount from '../components/create-account/create-account';
import { initSliderSnippetProduct } from '../components/snippet-product/snippet-product';
import { footer } from './footer';
import { work } from './work';

const FORM_ID_NEW_CLIENT = '#new_client';
const FORM_ID_EDIT = '#contacts';

$(() => {
    // eslint-disable-next-line
    setTimeout(() => {
        // Инициализация баннеров над шапкой
        document.querySelectorAll<HTMLDivElement>(SlimBannerTouch.cn.toSelector())
            .forEach(slimBannerElement => {
                const slimBannerTouch = new SlimBannerTouch(slimBannerElement);

                slimBannerTouch.onClose = () => {
                    document.body.style.paddingTop = SlimBannerTouch.getHeightForBody() + 'px';
                };
            });
        document.body.style.paddingTop = SlimBannerTouch.getHeightForBody() + 'px';

        header();
        footer();
        formDescr();

        if (document.querySelector('dt')) {
            work();
        }

        // eslint-disable-next-line
        const accordion = new Accordion();

        // eslint-disable-next-line
        const createAccount = new CreateAccount(() => {
            const authWrapper = document.querySelector('.account-auth');

            if (authWrapper) {
                authWrapper.classList.add('account-auth__links_hidden');
            }

            return;
        });

        // Загодловки страниц для авторизации регистрации и тд. Условия проверки страниц внутри метода
        ClientLogin.pageTitleFromUrl();

        EventBus.subscribe('update_items:insales:favorites_products', data => {
            const body: serCartBody = {
                productList: data.products.map(product => {
                    return {
                        product: {
                            ids: {
                                gate31: product.id
                            }
                        },
                        count: 1,
                        pricePerItem: parseFloat(product.variants[0].base_price)
                    };
                })
            };

            MindboxApi.setFavorite(body);
        });

        EventBus.subscribe('update_items:insales:cart', data => {
            const body: serCartBody = {
                productList: data.order_lines.map(variant => {
                    return {
                        product: {
                            ids: {
                                gate31: variant.variant_id
                            }
                        },
                        count: variant.quantity,
                        pricePerItem: variant.sale_price
                    };
                })
            };

            MindboxApi.setCart(body);
        });

        // Вызываем на каждой странице где есть превью товара
        if (document.querySelector('[data-ui-favorites-trigger]')) {
            Favorite.init();

            EventBus.subscribe('before:insales:favorites_products', data => {
                const id = data.item;

                if (data.method === 'add_item' && id) {
                    const headerFlag = document.querySelector('.header__flag .favorite__link');

                    if (headerFlag) {
                        headerFlag.classList.add('favorite-animate');

                        setTimeout(() => {
                            headerFlag.classList.remove('favorite-animate');
                        }, 500);
                    }
                }
            });
        }
        // end Вызываем на каждой странице где есть превью товара

        // eslint-disable-next-line
        new Select(); // Инициализация селектов

        if (
            [
                '/client_account/contacts/new',
                '/new_order'
            ].includes(window.location.pathname)
        ) {
            ClientLogin.init();
        }

        switch (window.location.pathname) {
            case '/client_account/contacts':  // Страница редактирования профиля
                ClientLogin.setCheckedSubscribeMethod();
                ClientLogin.toggleViewSubsribeMethod();
                ClientLogin.setValueCheckboxInForm();

                const client = new Client();

                client.handlerFormEditProfile();

                $(document).on('submit', `form${FORM_ID_EDIT}`, () => MindboxApi.editProfile());
                break;
            case '/page/reviews':
                // eslint-disable-next-line
                new ReviewsShop();

                break;
            case '/page/feedback':
                const reviewsShop = new ReviewsShop();

                reviewsShop.openReviewForm();
                break;
            case '/page/welcomeonboard-man':
                Subscription.init('form.welcomeonboard__subscribe-form');
                break;
            case '/page/welcomeonboard':
                Subscription.init('form.welcomeonboard__subscribe-form');
                break;
            default:
                break;
        }

        $(document).on('submit', `form${FORM_ID_NEW_CLIENT}`, () => {
            MindboxApi.signup({
                email: String($(`form${FORM_ID_NEW_CLIENT} #client_email`).val()),
                fullName: String($(`form${FORM_ID_NEW_CLIENT} #client_name`).val()),
                mobilePhone: String($(`form${FORM_ID_NEW_CLIENT} #client_phone `).val()),
                isSubscribed: String($(`form${FORM_ID_NEW_CLIENT} #client_subscribe`).is(':checked'))
            });
        });

        ClientLogin.setPlaceholderForInput();
        initSliderSnippetProduct();

        // client-account__active-base-content
        Client.openContentClientPages();
    }, 0);
});
