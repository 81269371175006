import $ from 'jquery';
import Template from 'Template';
import EventBus from 'EventBus';
import { updateHeaderModalHeight } from '@gate31/touch/components/menu/menu';
import Search from '@gate31/uikit/common/components/search/search';
import Modal from '@gate31/uikit/common/components/modal/modal';
import { setIcon } from '@gate31/uikit/common/components/icon/icon';
import { formDescr } from '@gate31/uikit/common/components/forms/forms';
import { updateCounterItemsFavorite } from '@gate31/uikit/common/components/counter/counter';

type DataAttrValues = 'search-button' | 'menu-button';

const HEADER_DATA_ATTR = 'data-header';
const HEADER_ELEMS_DATA_ATTR = {
    header: `[${HEADER_DATA_ATTR}="header"]`,
    searchButton: 'search-button',
    menuButton: 'menu-button'
};
const HEADER_CLASSES = {
    open: 'header_open'
};

export const header = () => {
    const $header = $(HEADER_ELEMS_DATA_ATTR.header);
    const headerWrap = document.querySelector('.header__container');

    window.addEventListener('orientationchange', () => {
        updateHeaderModalHeight();
    }, false);

    const openSearch = () => {
        const searchModal = new Modal({
            viewOpts: {
                padding: 'clear'
            },
            template: 'modal-template',
            disableBodyScroll: true,
            parent: 'body'
        });

        searchModal
            .open(() => {
                const search = new Search({
                    template: 'search-template',
                    itemTemplate: 'search-item-template',
                    selector: 'search-header'
                });

                searchModal.setContent(search.getHtml());
                search.init();
            });
    };

    const $iconMenu = $header.find('[data-header="menu-button"] use');

    const menuPanel = new Modal({
        viewOpts: {
            padding: 'clear',
            hideCloseBtn: true,
            className: 'header__modal'
        },
        disableBodyScroll: true,
        template: 'modal-template',
        parent: 'body'
    });

    const closeMenu = () => {
        menuPanel.close();
        $header.removeClass(HEADER_CLASSES.open);
        headerWrap?.classList.remove('header__menu-open');

        setIcon($iconMenu, 'menu');
    };

    const openMenu = () => {
        const content = Template.render({}, 'modal-header-template');

        headerWrap?.classList.add('header__menu-open');

        menuPanel.open().setContent(content);

        $header.addClass(HEADER_CLASSES.open);

        setIcon($iconMenu, 'close');

        formDescr();

        updateHeaderModalHeight();
    };

    $header.on('click', '[data-header*="-button"]', event => {
        event.preventDefault();
        event.stopPropagation();

        const target = (event.target as HTMLElement);
        const dataset = target.dataset as { header: DataAttrValues };

        switch (dataset.header) {
            case 'search-button':
                closeMenu();
                openSearch();
                break;
            case 'menu-button':
                if ($header.hasClass(HEADER_CLASSES.open)) {
                    closeMenu();
                } else {
                    openMenu();
                }
                break;
            default:
                return null;
        }
    });

    EventBus.subscribe('update_items:insales:favorites_products', data => {
        updateCounterItemsFavorite(data.products.length);
    });
};
