import { getCookie } from '@gate31/core/src/libs/utils';

export function cookieModal() {
    const closeBtn = document.querySelector('[data-cookie-close]');
    const cookieModalItem = document.querySelector('[data-modal-cookie]');

    // Добавляем значение по клику
    closeBtn?.addEventListener('click', () => {
        document.cookie = 'cookie-modal=false; path=/;';
        cookieModalItem?.classList.remove('cookie-modal__is-active');
    });

    const cookieModalValue = getCookie('cookie-modal');

    if (cookieModalValue !== 'false') {
        cookieModalItem?.classList.add('cookie-modal__is-active');
    }
}
