export function updateHeaderModalHeight() {
    const header = document.querySelector('[data-header="header"]');
    const headerHeight = header?.clientHeight;
    const headerModal = document.querySelector<HTMLElement>('.header__modal');
    const headerMenuContent = document.querySelector<HTMLElement>('.header__menu');

    if (headerModal !== null && headerMenuContent !== null) {
        headerModal.style.top = `${headerHeight}px`;
        headerModal.style.height = `calc(100vh - ${headerHeight}px)`;
        headerMenuContent.style.paddingBottom = `${headerHeight}px`;
    }
}
