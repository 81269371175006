import $ from 'jquery';
import { Product, jQueryDivType, jQueryFormType } from '@gate31/types';
import LiquidRender, { LiquidTemplates } from '../../scripts/liquid-render';

class SearchError extends Error {
    name = 'SearchError'
}

class SearchAbortError extends SearchError {
    name = 'SearchAbortError'
}

export default class Search {
    private readonly $body: jQueryDivType;
    private readonly $content: jQueryDivType;
    private readonly $form: jQueryFormType;
    private readonly template: keyof LiquidTemplates;
    private readonly selector: string;
    private readonly itemTemplate: keyof LiquidTemplates;

    private _request: ReturnType<typeof $.ajax> | null = null;

    constructor(opt: { template: keyof LiquidTemplates; selector: string; itemTemplate: keyof LiquidTemplates }) {
        this.template = opt.template;
        this.selector = opt.selector;
        this.itemTemplate = opt.itemTemplate;

        this.$body = $(LiquidRender.render(this.template, {}));
        this.$content = this
            .$body
            .find<HTMLDivElement>(this.getDataAttrElem().content);
        this.$form = this.$body.find<HTMLFormElement>(this.getDataAttrElem().form);
    }

    getDataAttrElem() {
        return {
            body: `[data-search="${this.selector}"]`,
            form: `[data-search="${this.selector}-form"]`,
            content: `[data-search="${this.selector}-results"]`
        };
    }

    init() {
        const input = this.$form.find<HTMLInputElement>('input')[0];

        input.focus();
        input.scrollIntoView();

        this.$form.on('input', e => {
            this.search({
                q: e.target.value,
                page_size: 10,
                available: true
            })
                .then(response => {
                    if (Array.isArray(response)) {
                        if (response.length) {
                            this.renderItems(response);
                        } else {
                            this.renderEmpty();
                        }
                    }
                }).catch(error => {
                    if (error?.statusText === SearchAbortError.name) {
                        return null;
                    }

                    this.renderError();
                });
        });
    }

    search(data = {}) {
        return new Promise((resolve, reject) => {
            if (this._request) {
                this._request.abort(SearchAbortError.name);
            }

            this._request = $.ajax({
                url: '/search.json',
                dataType: 'json',
                data,
                success: (response: Array<Product>) => {
                    this._request = null;

                    resolve(response);
                },
                error(error) {
                    reject(error);
                }
            });
        });
    }

    renderError() {
        this.$content.html('Ошибка получения данных');
    }

    renderEmpty() {
        this.$content.html('');
    }

    renderItems(data: Product[]) {
        const itemsHtml = LiquidRender.render(this.itemTemplate, {
            data: data.map(product => ({
                url: product.url,
                title: product.title,
                img: product.images[0].medium_url
            }))
        });

        this.$content.html(itemsHtml);
    }

    getHtml(): jQueryDivType {
        return this.$body;
    }

    destroy() {
        this.$body.remove();
    }
}
